import React from "react"
import { LocationProvider } from "@reach/router"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import TourismHero from "../components/sustainable_tourism/TourismHero"
import TourismContent from "../components/sustainable_tourism/TourismContent"
import ContactLead from "../components/ContactLead"

const Envision = () => {
  return (
    <>
      <Seo title="Sustainable Tourism | ENVision" />
      <BlurredSection>
        <LocationProvider>
          <TourismHero events="envision" />
          <TourismContent />
        </LocationProvider>
        <ContactLead />
      </BlurredSection>
    </>
  )
}

export default Envision
